import Vue from 'vue';
import EngineerThreadsAPI from '../../api/engineerThreads';

export const state = {
  records: {},
  uiFlags: {
    isFetchingThread: false,
    isFetchingMessages: false,
    isFetchingFreeThread: false,
  },
  currentThreadId: null,
  messages: [],
  freeThread: {
    data: null,
    messages: [],
  },
  conversationThread: {
    data: null,
    messages: [],
  },
};

export const getters = {
  getThreadByConversationId: _state => conversationId => {
    return Object.values(_state.records).find(
      thread => thread.conversation_id === conversationId
    );
  },
  getCurrentThread: _state => _state.records[_state.currentThreadId] || null,
  getThreadMessages: _state => {
    // Se siamo in un thread di conversazione, restituiamo i messaggi della conversazione
    if (
      _state.currentThreadId &&
      _state.records[_state.currentThreadId]?.thread_type === 'conversation'
    ) {
      return _state.conversationThread.messages;
    }
    // Altrimenti restituiamo i messaggi del thread libero
    return _state.freeThread.messages;
  },
  getFreeThread: _state => _state.freeThread.data,
  getFreeThreadMessages: _state => _state.freeThread.messages,
  getConversationThread: _state => _state.conversationThread.data,
  getConversationThreadMessages: _state => _state.conversationThread.messages,
  getUIFlags: _state => _state.uiFlags,
};

export const actions = {
  async fetch({ commit, state }, { conversationId, signal }) {
    console.log('🔍 [engineerThreads/fetch] Inizio fetch thread', {
      conversationId,
      currentState: {
        currentThreadId: state.currentThreadId,
        messagesCount: state.conversationThread.messages.length,
        isFetchingThread: state.uiFlags.isFetchingThread,
      },
    });

    if (!conversationId) {
      console.error('❌ [engineerThreads/fetch] conversationId mancante');
      return null;
    }

    // Forziamo il reset del flag se è rimasto bloccato
    if (state.uiFlags.isFetchingThread) {
      console.warn('⚠️ [engineerThreads/fetch] Reset flag fetching bloccato');
      commit('SET_UI_FLAG', {
        isFetchingThread: false,
      });
    }

    // Verifichiamo se stiamo già gestendo questo thread
    if (state.currentThreadId) {
      const currentThread = state.records[state.currentThreadId];
      if (currentThread?.conversation_id === conversationId) {
        console.log('ℹ️ [engineerThreads/fetch] Thread già caricato, skip');
        return currentThread;
      }
    }

    commit('SET_UI_FLAG', {
      isFetchingThread: true,
    });

    try {
      // Puliamo subito l'UI quando entriamo in una conversazione
      console.log('🧹 [engineerThreads/fetch] Inizio pulizia UI');
      commit('CLEAR_CONVERSATION_THREAD');
      commit('SET_CURRENT_THREAD_ID', null);

      console.log(
        '🔄 [engineerThreads/fetch] Chiamata API getByConversationId...'
      );
      const response =
        await EngineerThreadsAPI.getByConversationId(conversationId);

      if (!response) {
        throw new Error('Risposta API vuota');
      }

      const thread = Array.isArray(response.data)
        ? response.data[0]
        : response.data;
      console.log('✅ [engineerThreads/fetch] Thread estratto:', thread);

      if (thread) {
        await commit('SET_THREAD', thread);
        await commit('SET_CURRENT_THREAD_ID', thread.id);
        console.log('✅ [engineerThreads/fetch] Thread settato con successo');
        return thread;
      }

      console.log('ℹ️ [engineerThreads/fetch] Nessun thread trovato');
      return null;
    } catch (error) {
      console.error('❌ [engineerThreads/fetch] Errore:', error);
      return null;
    } finally {
      commit('SET_UI_FLAG', {
        isFetchingThread: false,
      });
    }
  },

  async fetchMessages({ commit, state }, { threadId }) {
    console.log('🔍 [engineerThreads/fetchMessages] Inizio fetch messaggi', {
      threadId,
    });

    if (state.uiFlags.isFetchingMessages) {
      console.warn(
        '⚠️ [engineerThreads/fetchMessages] Fetch già in corso, skip'
      );
      return;
    }

    if (!threadId) {
      console.error('❌ [engineerThreads/fetchMessages] ThreadId mancante');
      return;
    }

    commit('SET_UI_FLAG', {
      isFetchingMessages: true,
    });

    try {
      console.log(
        '🔄 [engineerThreads/fetchMessages] Chiamata API getMessages'
      );
      const response = await EngineerThreadsAPI.getMessages(threadId);
      const messages = response.data;
      console.log(
        '✅ [engineerThreads/fetchMessages] Messaggi ricevuti:',
        messages
      );

      // Determiniamo il tipo di thread
      const thread = state.records[threadId];
      const threadType = thread?.thread_type || 'free';

      commit('SET_MESSAGES', { messages, threadType });
      return messages;
    } catch (error) {
      console.error('❌ [engineerThreads/fetchMessages] Errore:', error);
      return [];
    } finally {
      commit('SET_UI_FLAG', {
        isFetchingMessages: false,
      });
    }
  },

  async createOrGetFreeThread({ commit, state }) {
    console.log(
      '🔍 [engineerThreads/createOrGetFreeThread] Cerco thread libero'
    );

    if (state.uiFlags.isFetchingFreeThread) {
      console.warn(
        '⚠️ [engineerThreads/createOrGetFreeThread] Fetch già in corso, skip'
      );
      return;
    }

    commit('SET_UI_FLAG', {
      isFetchingFreeThread: true,
    });

    let thread = null;

    try {
      const response = await EngineerThreadsAPI.getFreeThread();
      if (response.data) {
        console.log(
          '✅ [engineerThreads/createOrGetFreeThread] Thread libero trovato'
        );
        thread = response.data;
      }
    } catch (error) {
      // Se è 404 proviamo a creare un nuovo thread
      if (error.response?.status === 404) {
        console.log(
          'ℹ️ [engineerThreads/createOrGetFreeThread] Nessun thread libero trovato'
        );
        try {
          console.log(
            '🆕 [engineerThreads/createOrGetFreeThread] Creo nuovo thread libero'
          );
          const newThread = await EngineerThreadsAPI.createFreeThread();
          thread = newThread.data;
        } catch (createError) {
          console.error(
            '❌ [engineerThreads/createOrGetFreeThread] Errore nella creazione:',
            createError
          );
          throw createError;
        }
      } else {
        console.error(
          '❌ [engineerThreads/createOrGetFreeThread] Errore nel recupero:',
          error
        );
        throw error;
      }
    } finally {
      // Resettiamo sempre il flag alla fine
      commit('SET_UI_FLAG', {
        isFetchingFreeThread: false,
      });
    }

    // Se abbiamo un thread (esistente o nuovo), lo settiamo
    if (thread) {
      commit('SET_THREAD', thread);
      commit('SET_CURRENT_THREAD_ID', thread.id);
      return thread;
    }

    return null;
  },

  async createConversationThread({ commit }, { conversationId }) {
    console.log(
      '🔍 [engineerThreads/createConversationThread] Inizio creazione CONVERSATION thread',
      {
        conversationId,
      }
    );

    if (!conversationId) {
      console.error(
        '❌ [engineerThreads/createConversationThread] conversationId mancante'
      );
      throw new Error(
        'conversationId è richiesto per creare un thread di conversazione'
      );
    }

    try {
      console.log(
        '🆕 [engineerThreads/createConversationThread] Creo thread di conversazione'
      );
      const response =
        await EngineerThreadsAPI.createConversationThread(conversationId);

      if (!response.data) {
        throw new Error('Risposta API vuota');
      }

      const thread = response.data;
      console.log(
        '✅ [engineerThreads/createConversationThread] Thread creato:',
        thread
      );

      commit('SET_THREAD', thread);
      commit('SET_CURRENT_THREAD_ID', thread.id);
      return thread;
    } catch (error) {
      console.error(
        '❌ [engineerThreads/createConversationThread] Errore:',
        error
      );
      throw new Error('Impossibile creare il thread di conversazione');
    }
  },

  async createMessage(
    { commit, state, dispatch },
    { threadId, content, role = 'user', conversationId }
  ) {
    console.log(
      '🔍 [engineerThreads/createMessage] Inizio creazione messaggio',
      {
        threadId,
        content,
        role,
        conversationId,
      }
    );

    try {
      if (conversationId && !threadId) {
        console.log(
          '🆕 [engineerThreads/createMessage] Creo nuovo thread per conversazione'
        );
        const newThread =
          await EngineerThreadsAPI.createConversationThread(conversationId);
        threadId = newThread.data.id;
        commit('SET_THREAD', newThread.data);
        commit('SET_CURRENT_THREAD_ID', newThread.data.id);
      }

      const response = await EngineerThreadsAPI.createMessage(threadId, {
        thread_message: {
          role,
          content,
        },
      });

      const message = response.data;
      console.log(
        '✅ [engineerThreads/createMessage] Messaggio creato:',
        message
      );

      // Determiniamo il tipo di thread
      const thread = state.records[threadId];
      const threadType = thread?.thread_type || 'free';

      commit('ADD_MESSAGE', { message, threadType });
      return message;
    } catch (error) {
      console.error('❌ [engineerThreads/createMessage] Errore:', error);
      throw error;
    }
  },

  clearCurrentThread({ commit }) {
    console.log(
      '🧹 [engineerThreads/clearCurrentThread] Pulizia thread corrente'
    );
    commit('SET_CURRENT_THREAD_ID', null);
    commit('CLEAR_FREE_THREAD');
    commit('CLEAR_CONVERSATION_THREAD');
  },

  async archiveThread({ commit, state, dispatch }) {
    console.log(
      '🗑️ [engineerThreads/archiveThread] Inizio archiviazione thread'
    );
    const threadId = state.currentThreadId;

    if (!threadId) {
      console.warn(
        '⚠️ [engineerThreads/archiveThread] Nessun thread attivo da archiviare'
      );
      return;
    }

    try {
      // Salviamo il tipo di thread prima di archiviarlo
      const threadType = state.records[threadId]?.thread_type;
      console.log(
        'ℹ️ [engineerThreads/archiveThread] Tipo thread:',
        threadType
      );

      await EngineerThreadsAPI.update(threadId, {
        is_active: false,
        is_deleted: true,
      });

      console.log(
        '✅ [engineerThreads/archiveThread] Thread archiviato con successo'
      );

      // Reset dello stato
      commit('CLEAR_FREE_THREAD');
      commit('CLEAR_CONVERSATION_THREAD');
      commit('SET_CURRENT_THREAD_ID', null);

      // Se era un thread libero, ne creiamo subito uno nuovo
      if (threadType === 'free') {
        console.log(
          '🔄 [engineerThreads/archiveThread] Creo nuovo thread libero dopo archiviazione'
        );
        await dispatch('createOrGetFreeThread');
      }

      return true;
    } catch (error) {
      console.error('❌ [engineerThreads/archiveThread] Errore:', error);
      throw error;
    }
  },

  async switchToFreeThread() {
    console.log('🔄 [RightSidebar] Passaggio a thread libero');

    try {
      // Non puliamo qui lo stato perché lo fa già il watcher
      const thread = await this.$store.dispatch(
        'engineerThreads/createOrGetFreeThread'
      );

      if (thread) {
        console.log(
          '✅ [RightSidebar] Thread libero trovato/creato:',
          thread.id
        );
        await this.$store.dispatch('engineerThreads/fetchMessages', {
          threadId: thread.id,
        });
      }
    } catch (error) {
      console.error(
        '❌ [RightSidebar] Errore nel recupero/creazione thread libero:',
        error
      );
    }
  },
};

export const mutations = {
  SET_THREAD(_state, thread) {
    console.log('📝 [mutation/SET_THREAD] Input:', thread);
    try {
      const records = {};
      records[thread.id] = thread;
      Vue.set(_state, 'records', records);

      // Aggiorniamo anche il thread specifico in base al tipo
      if (thread.thread_type === 'free') {
        Vue.set(_state.freeThread, 'data', thread);
      } else if (thread.thread_type === 'conversation') {
        Vue.set(_state.conversationThread, 'data', thread);
      }

      console.log('✅ [mutation/SET_THREAD] State aggiornato:', _state.records);
    } catch (error) {
      console.error('❌ [mutation/SET_THREAD] Errore:', error);
      throw error;
    }
  },

  ADD_THREAD(_state, thread) {
    console.log('📝 [mutation/ADD_THREAD]', thread);
    Vue.set(_state.records, thread.id, thread);

    // Aggiorniamo anche il thread specifico in base al tipo
    if (thread.thread_type === 'free') {
      Vue.set(_state.freeThread, 'data', thread);
    } else if (thread.thread_type === 'conversation') {
      Vue.set(_state.conversationThread, 'data', thread);
    }
  },

  SET_CURRENT_THREAD_ID(_state, threadId) {
    console.log('📝 [mutation/SET_CURRENT_THREAD_ID] Input:', threadId);
    try {
      _state.currentThreadId = threadId;
      console.log(
        '✅ [mutation/SET_CURRENT_THREAD_ID] State aggiornato:',
        _state.currentThreadId
      );
    } catch (error) {
      console.error('❌ [mutation/SET_CURRENT_THREAD_ID] Errore:', error);
      throw error;
    }
  },

  SET_MESSAGES(_state, { messages, threadType }) {
    console.log('📝 [mutation/SET_MESSAGES] Input:', { messages, threadType });
    try {
      if (threadType === 'free') {
        Vue.set(_state.freeThread, 'messages', messages || []);
      } else if (threadType === 'conversation') {
        Vue.set(_state.conversationThread, 'messages', messages || []);
      }
      console.log(
        '✅ [mutation/SET_MESSAGES] State aggiornato per',
        threadType
      );
    } catch (error) {
      console.error('❌ [mutation/SET_MESSAGES] Errore:', error);
      throw error;
    }
  },

  ADD_MESSAGE(_state, { message, threadType }) {
    console.log('📝 [mutation/ADD_MESSAGE]', { message, threadType });
    if (threadType === 'free') {
      if (!Array.isArray(_state.freeThread.messages)) {
        Vue.set(_state.freeThread, 'messages', []);
      }
      _state.freeThread.messages.push(message);
    } else if (threadType === 'conversation') {
      if (!Array.isArray(_state.conversationThread.messages)) {
        Vue.set(_state.conversationThread, 'messages', []);
      }
      _state.conversationThread.messages.push(message);
    }
  },

  CLEAR_FREE_THREAD(_state) {
    Vue.set(_state.freeThread, 'data', null);
    Vue.set(_state.freeThread, 'messages', []);
  },

  CLEAR_CONVERSATION_THREAD(_state) {
    Vue.set(_state.conversationThread, 'data', null);
    Vue.set(_state.conversationThread, 'messages', []);
  },

  SET_UI_FLAG(_state, flags) {
    try {
      const newFlags = {
        ..._state.uiFlags,
        ...flags,
      };
      Vue.set(_state, 'uiFlags', newFlags);
    } catch (error) {
      console.error('❌ [mutation/SET_UI_FLAG] Errore:', error);
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
