import ApiClient from './ApiClient';

const engineerThreadsClient = new ApiClient('engineer_threads', {
  accountScoped: true,
});

const EngineerThreadsAPI = {
  getAll() {
    return engineerThreadsClient.get();
  },

  getByConversationId(conversationId) {
    console.log('🔄 [engineerThreads/API] Chiamata getByConversationId:', {
      conversationId,
      url: `${engineerThreadsClient.url}/by_conversation/${conversationId}`,
    });
    return engineerThreadsClient.get(`by_conversation/${conversationId}`);
  },

  getFreeThread() {
    return engineerThreadsClient.get('free_thread');
  },

  getMessages(threadId) {
    return engineerThreadsClient.get(`${threadId}/thread_messages`);
  },

  create(data) {
    if (data.conversation_id) {
      data.thread_type = 'conversation';
    }
    return engineerThreadsClient.create(data);
  },

  createFreeThread() {
    return engineerThreadsClient.create({ thread_type: 'free' });
  },

  createConversationThread(conversationId) {
    return engineerThreadsClient.create({
      conversation_id: conversationId,
      thread_type: 'conversation',
    });
  },

  update(threadId, data) {
    return engineerThreadsClient.update(threadId, data);
  },

  createMessage(threadId, data) {
    return engineerThreadsClient.post(`${threadId}/thread_messages`, data);
  },
};

export default EngineerThreadsAPI;
